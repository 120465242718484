<template>
    <b-card body-class="p-0" header-class="border-0">
      <template v-slot:header>
        <b-row align-v="center">
          <b-col sm="12" lg="4">
            <h3 class="mb-0 title">
              Traders
  
              <button
                class="btn btn-sm btn-primary add-user-sm"
                @click="showModal()"
              >
                <span><i class="fas fa-plus"></i></span>
              </button>
            </h3>
          </b-col>
          <b-col sm="8" lg="4">
            <b-form-group
              id="search-wrapper"
              label-for="input-1"
              v-bind:class="{ active: isActive, focused: isFocused }"
            >
              <b-form-input
                id="filter-input"
                ref="filterinput"
                type="text"
                placeholder="Search Traders"
                v-model="query"
                @keyup="filterTableBySearch"
                @blur.native="removeCancelSearchIcon"
                @focus="focused"
              ></b-form-input>
              <span class="clear-search" @click="cancelFilterBySearch()">
                <i class="fas fa-times"></i>
              </span>
            </b-form-group>
          </b-col>
          <b-col class="text-right add-user-ds" md="4" lg="4">
            <button class="btn btn-sm btn-primary" @click="showModal()">
              Add Trader
            </button>
          </b-col>
        </b-row>
      </template>
      <spinner v-if="isLoading"></spinner>
       <!-- Table -->
      <el-table
        id="thriveTraderTable"
        class="table-responsive table"
        :data="displayData"
        header-row-class-name="thead-light"
        v-if="!isLoading"
        @sort-change="sortChange"
      >
        <el-table-column
          label="Thrive Id"
          min-width="60px"
          prop="traderId"
          sortable
        >
          <template v-slot="{ row }">
            <div class="font-weight-600">{{ row.traderId }}</div>
          </template>
        </el-table-column>

        <el-table-column label="Actions" min-width="60px">
          <template v-slot="{ row }">
            <div class="row-buttons">
              <button
                class="btn btn-sm btn-primary"
                :data-id="row.traderId"
                @click="showOfferModal(row.traderId)"
              >
                <span class="button-text">Send offer</span>
                <span class="button-icon"><i class="fas fa-envelope"></i></span>
              </button>
            </div>
          </template>
        </el-table-column>
        
      </el-table>
      <el-pagination
        :current-page="currentPage"
        @current-change="handleCurrentChange"
        :total="rows"
        :page-size="pageSize"
        align="right"
      >
      </el-pagination>
  
      <!-- Add Trader -->
      <modal
        :show="isModalVisible"
        @close="closeModal"
        :hide-footer="true"
        class="user-modal"
      >
        <!-- <b-form
          class="form"
          @submit.prevent="onSubmit"
          v-if="!loading && success == null && error == null"
        > -->
          <b-form-group id="input-group-1" label="Trader ID:" label-for="input-1">
            <b-form-input
              id="input-1"
              v-model="form.traderId"
              type="text"
              placeholder="Enter Trader ID"
              required
            ></b-form-input>
          </b-form-group>
  
          <b-button @click="onAddTraderIdSubmit" type="submit" variant="primary">Submit</b-button>
          <b-button
            @click="closeModalHandler"
            type="button"
            variant="primary cancel"
            >Cancel</b-button
          >
        <!-- </b-form> -->
        <!-- <div class="success-message" v-if="loading">
          <spinner v-if="loading"></spinner>
        </div>
        <div class="success-message" v-if="success">
          <div class="icon-wrapper">
            <i class="fas fa-check"></i>
          </div>
          <p>Trader added successfully!</p>
        </div>
        <div class="success-message error" v-if="error != null">
          <div class="message-icon">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <p>{{ error }}</p>
          <b-button @click="closeModalHandler" type="button" variant="primary"
            >Close</b-button
          >
        </div> -->
      </modal>
  
      <!-- Send Offer -->
  
      <modal
        :show="offerModal"
        @close="closeModal"
        :hide-footer="true"
        class="user-modal"
      >
        <!-- <b-form
          class="form"
          @submit.prevent="onSendOfferSubmit"
          v-if="!loading && success == null && editState == false"
        > -->
          <div class="col-md-12">
            <b-form-group
              id="input-group-3"
              label="Trader ID:"
              label-for="input-3"
            >
              <b-form-input
                id="input-3"
                v-model="this.selectedId"
                type="text"
                placeholder="Selected Trader ID"
                required
                disabled="true"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Ignore late reduction" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="form.ignoreLateReduction"
                :options="ignoreLateReductionOptions"
                :aria-describedby="ariaDescribedby"
                name="ignore-late"
                required
              ></b-form-radio-group>
            </b-form-group>

            <b-form-group label="Ignore trade reduction" v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                v-model="form.ignoreTradeReduction"
                :options="ignoreTradeReductionOptions"
                :aria-describedby="ariaDescribedby"
                name="ignore-trade"
                required
              ></b-form-radio-group>
            </b-form-group>
          </div>
            
          <b-button @click="onSendOfferSubmit" type="submit" variant="primary">
            Send offer
          </b-button>
          <b-button @click="closeModal" type="button" variant="primary cancel"
            >Cancel</b-button
          >
        <!-- </b-form> -->
        <!-- <div class="success-message" v-if="loading && !editState">
          <spinner v-if="loading"></spinner>
        </div>
        <div class="success-message" v-if="editState">
          <div class="icon-wrapper">
            <i class="fas fa-check"></i>
          </div>
          <p>Offer sent successfully!</p>
          <b-button @click="closeUpdateModal" type="button" variant="primary"
            >Close</b-button
          >
        </div> -->
      </modal>
    </b-card>
  </template>
  <script>
  import {
    Table,
    TableColumn,
    DropdownMenu,
    DropdownItem,
    Dropdown,
    Pagination
  } from "element-ui";
  import modal from "../../components/Modal.vue";
  import config from "../../config";
  import Spinner from "../../components/Spinner.vue";
  // import { sha256 } from "js-sha256";
  
  export default {
    name: "thriveTraders-table",
    components: {
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Dropdown.name]: Dropdown,
      [DropdownItem.name]: DropdownItem,
      [DropdownMenu.name]: DropdownMenu,
      [Pagination.name]: Pagination,
      modal,
      Spinner
    },
    data() {
      return {
        selected: 0,
        isActive: false,
        isFocused: false,
        isLoading: false,
        isModalVisible: false,
        offerModal: false,
        addTraderModal: false,
        currentPage: 1,
        pageSize: 10,
        tableData: [
          {
            traderId: "0"
          }
        ],
        masterData: [
          {
            traderId: "0"
          }
        ],
        form: {
          traderId: "",
          ignoreLateReduction: false,
          ignoreTradeReduction: false
        },
        query: "",
        selectedId: 0
      };
    },
    computed: {
      rows() {
        return this.tableData.length;
      },
      displayData() {
        console.log("getting display data");
        if (!this.tableData || this.tableData.length === 0) return [];
        return this.tableData.slice(
          this.pageSize * this.currentPage - this.pageSize,
          this.pageSize * this.currentPage
        );
      },
      ignoreLateReductionOptions(){
        const options = [
          { text: 'Yes', value: true },
          { text: 'No', value: false}    
        ];

        return options;
      },
      ignoreTradeReductionOptions(){
        const options2 = [
          { text: 'Yes', value: true },
          { text: 'No', value: false}    
        ];

        return options2;
      },
    },
    methods: {
      sortChange($event) {
        // Get sort change data
        const { prop, order } = $event;
        console.log($event);
        // Check for sort order and table data accordingly
        if (order == "ascending") {
          this.tableData = this.tableData.sort((a, b) =>
            a[prop] > b[prop] ? 1 : -1
          );
        }

        if (order == "descending") {
          this.tableData = this.tableData.sort((a, b) =>
            a[prop] < b[prop] ? 1 : -1
          );
        }
        console.log("Table data:", this.tableData);
      },
      filterTableBySearch() {
        // Get all clients from store
        const traders = this.masterData;
        // Filter clients by search query
        console.log("Query:", this.query);
        const filteredTraders = traders.filter(
          (c) =>
            c.traderId.includes(this.query)
        );
        // Store the filtered data as the new tatble data values
        this.tableData = filteredTraders;
      },
      resetTableData() {
        // When query length is zero input is in empty state, we get client from store
        // Store clients from store and update table data
        this.tableData = this.masterData;
      },
      cancelFilterBySearch() {
        // Get the search input and set it to null/empty
        const input = (this.$refs.filterinput.$el.value = "");
        // Remove focused class
        this.isFocused = false;
        // Remove active class
        this.isActive = false;
        // Reset table data
        this.resetTableData();
      },
      removeCancelSearchIcon($event) {
        // Get typed in value in input
        const queryLength = $event.target.value.trimStart().length;
        // Remove cancel icons based on length value length
        if (queryLength == 0) {
          // Reverse is active
          this.isActive = false;
          // Reverse is focused
          this.isFocused = false;
        }
      },
      onReset() {
        // Resets form values
        this.form.traderId = "";
        this.form.ignoreLateReduction = false;
        this.form.ignoreTradeReduction = false;
      },
      focused() {
        // Set focused class on search input wrapper
        this.isFocused = true;
      },
      handleCurrentChange(val) {
        this.currentPage = val;
      },
      showOfferModal(traderId) {
        this.selectedId = traderId;
        console.log('TEST',traderId, this.selectedId)
        this.offerModal = true;
      },
      onSendOfferSubmit() {
        var http = this.axios.create({
          baseURL: config.apiUrl,
          timeout: 30000,
          headers: { Authorization: this.$store.getters["accounts/token"] }
        });
        http.post("/data/send-offer", {
          traderId: this.selectedId,
          ignoreLateReduction: this.form.ignoreLateReduction,
          ignoreTradeReduction: this.form.ignoreTradeReduction
        }).then(response => {
          this.handleCurrentChange(1);
          this.isLoading = false;
          this.closeModal();
        });
      },
      onAddTraderIdSubmit() {
        var http = this.axios.create({
          baseURL: config.apiUrl,
          timeout: 30000,
          headers: { Authorization: this.$store.getters["accounts/token"] }
        });
        http.post("/data/add-trader", {
          traderId: this.form.traderId
        }).then(response => {
          this.tableData = response.data;
          this.masterData = response.data;
          this.handleCurrentChange(1);
          this.isLoading = false;
          this.closeModal();
        });
      },
      showModal() {
        // Ensure form is clear
        this.onReset();
        // Change updatingUser state to true
        // this.updatingUser = false;
        this.isModalVisible = true;
      },
      closeModal() {
        // Close modal

        this.offerModal = false;
        this.isModalVisible = false;
        // Reset form fields
        //this.onReset();
        setTimeout(() => {
          this.onReset();
        }, 500);
      },
      closeModalHandler() {
        this.isModalVisible = false;
        this.offerModal = false;
        this.addTraderModal = false;

        // this.onReset();
        // setTimeout(() => {
        //   this.$store.dispatch("users/complete");
        // }, 500);
      }
    },
    mounted() {
      //this.isLoading = true;
      var http = this.axios.create({
        baseURL: config.apiUrl,
        timeout: 30000,
        headers: { Authorization: this.$store.getters["accounts/token"] }
      });
      http.get("/data/traders").then(response => {
        this.tableData = response.data;
        this.masterData = response.data;
        console.log('Data:', this.tableData);

        this.handleCurrentChange(1);
        this.isLoading = false;
      });
    }
  };
  </script>
  <style>
  .buttons {
    text-align: center;
    margin-bottom: 10px;
  }
  
  .user-modal {
    display: none;
  }
  
  .cancel {
    color: #212529;
    background-color: #d2e3ee;
    border-color: #d2e3ee;
  }
  
  .cancel:hover {
    background-color: #d2e3ee;
    border-color: #d2e3ee;
  }
  
  .icon-wrapper {
    width: 60px;
    height: 60px;
    background: #f7941d;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 5px;
  }
  
  .icon-wrapper i {
    font-size: 30px;
    color: #fff;
  }
  
  .success-message {
    text-align: center;
    margin: 0 auto;
    margin-top: 10px;
  }
  
  .success-message p {
    color: #f7941d;
    margin-top: 15px;
  }
  
  .report-times {
    display: inline-block;
    width: 100%;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .report-times .title {
    color: #f7941d;
    font-size: 12px;
  }
  
  .devices {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
    list-style: none;
    padding: 0 5px;
    border-bottom: 1px solid #ebeef5;
    margin-top: 15px;
    display: inline-block;
    width: 100%;
    margin-bottom: 35px;
  }
  
  .devices .device {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ebeef5;
    padding: 10px 0;
  }
  
  .devices .device button {
    color: #fff;
    background-color: #f1f1f1;
    border-color: #f7941d;
    outline: 0;
    border: 0;
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
  
  .devices .device button i {
    color: #f7941d;
  }
  
  .error .message-icon {
    background: #f5f5f5;
  }
  
  .error .message-icon i {
    color: #f7941d;
  }
  
  .message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 0 50px;
  }
  
  .message-icon {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  
  .message.delete {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .message.delete .message-icon {
    background: #f5f5f5;
  }
  
  .message.delete .message-icon i {
    color: #f7941d;
    margin-top: -4px;
  }
  
  .message-icon i {
    font-size: 30px;
  }
  
  .message span {
    color: #525f7f;
    font-size: 15px;
    display: inline-block;
    margin-bottom: 20px;
  }
  
  .form-control:focus,
  .form-control:active {
    background: #fff !important;
  }
  
  .label {
    font-size: 12px;
  }
  
  .periods {
    display: flex;
    margin-top: 5px;
    flex-wrap: wrap;
  }
  
  .add-user .periods {
    margin-top: -10px;
    margin-bottom: 10px;
  }
  
  .add-user span {
    font-size: 12px;
    position: relative;
    display: flex;
    align-items: center;
    font-weight: 600;
    display: flex;
    margin-right: 5px;
    margin-bottom: 7px;
  }
  
  .add-user span a {
    text-decoration: underline;
    font-size: 12px;
    display: inline-block;
    margin-right: 5px;
  }
  
  .add-user span .remove {
    margin-top: 5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    padding: 4px;
    color: #f7941d;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-content: center;
    background-color: #f1f1f1;
    cursor: pointer;
  }
  
  .add-user span.time .text {
    padding-left: 5px;
    line-height: 0 !important;
    display: inline-block;
    margin-bottom: -1px;
    padding-left: 5px;
    font-size: 11px;
    margin-left: -3px;
  }
  
  .add-user .time .remove {
    display: flex;
    border-radius: 50%;
    background: #fff;
    width: 15px;
    height: 15px;
    position: absolute;
    right: 4px;
    cursor: pointer;
    color: #f7941d;
    justify-content: center;
    align-items: center;
    font-size: 10px;
  }
  
  span.time {
    background: #f7941d;
    border-radius: 25px;
    font-size: 12px;
    color: #fff !important;
    position: relative;
    max-width: 75px;
    display: flex;
    align-items: center;
    padding: 5px;
    font-weight: 600;
    display: flex;
    min-height: 30px;
    min-width: 75px;
    margin-right: 10px;
  
    margin-bottom: 7px;
  }
  
  span.time .text {
    padding-left: 5px;
    line-height: 0 !important;
    display: inline-block;
    margin-bottom: -1px;
    padding-left: 5px;
  }
  
  .time .remove {
    display: flex;
    border-radius: 50%;
    background: #fff;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 5px;
    cursor: pointer;
    color: #f7941d;
    justify-content: center;
    align-items: center;
  }
  
  .time-error {
    color: #f7941d;
    font-size: 12px;
  }
  
  .form-control[readonly] {
    background-color: #fff !important;
  }
  
  #search-wrapper {
    position: relative;
    margin: 0;
  }
  
  #search-wrapper.focused input {
    border-color: #f7941d;
  }
  
  #search-wrapper.active .clear-search {
    display: flex;
  }
  
  #search-wrapper .clear-search {
    padding-top: 2px;
    position: absolute;
    top: 50%;
    right: 15px;
    display: flex;
    width: 20px;
    height: 20px;
    background: #f1f1f1;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: none;
  }
  #search-wrapper .clear-search i {
    font-size: 13px;
    line-height: none;
    padding: 0;
    margin: 0;
    color: #525f7f;
  }
  </style>
  